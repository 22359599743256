import {KTSVG} from '../../../../../../_metronic/helpers'
import {Button} from '../../../../../components/inputs/Button'
import {Spinner} from '../../../../../components/utils/Spinner'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {isTicketDownloadable, isTicketIsAddToWallet} from '../../../hooks/useTicketTableActions'
import {RowAction} from '../../../../../components/tables/actions/RowAction'
import clsx from 'clsx'
import {useState} from 'react'

export interface TicketTableRowActionsProps {
  onView?: (data: TicketPortalModel) => void
  onDownload?: (data: TicketPortalModel[]) => void
  onWallet?: (data: TicketPortalModel) => void
  onShareByWahtsApp?: (data: TicketPortalModel) => void
  onShareByEmail?: (data: TicketPortalModel) => void
  onReassignSeat?: (data: TicketPortalModel) => void
  onCancel?: (data: TicketPortalModel) => void
  onTicketDownload?: (data: TicketPortalModel) => void
  onShareViaEmail?: (data: TicketPortalModel) => void
  data: TicketPortalModel
  loading?: boolean
  iconWidth?: string
}

export const TicketTableRowActions = ({
  onView,
  onDownload,
  onWallet,
  onShareByWahtsApp,
  onShareByEmail,
  data,
  loading,
  iconWidth,
  onCancel,
  onTicketDownload,
  onShareViaEmail,
}: TicketTableRowActionsProps) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      <div className='d-flex gap-3'>
        {isTicketDownloadable(data) && (
          <RowAction
            loading={loading}
            data={data}
            iconType='Files'
            iconName='Download'
            variant='primary'
            tooltip='Download'
            onClick={onTicketDownload}
          />
        )}
        {onCancel && (
          <RowAction
            loading={loading}
            data={data}
            iconType='Code'
            iconName='Stop'
            variant='danger'
            tooltip='Recall Ticket'
            onClick={onCancel}
          />
        )}
        {onView && (
          <Button
            type='button'
            className='bg-transparent p-2'
            tooltip='View'
            size='sm'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/view.svg'
              onClick={() => onView(data)}
            />
          </Button>
        )}

        {onDownload && isActive(data) && (
          <Button
            type='button'
            className='bg-transparent p-2'
            tooltip='Download'
            size='sm'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
          >
            {loading ? (
              <Spinner className='me-5' size='lg' />
            ) : (
              <KTSVG
                color='white'
                className='h-100 w-100'
                svgClassName='h-100 w-100'
                path='/media/svg/customer-poratl-actions/download.svg'
                onClick={() => onDownload([data])}
              />
            )}
          </Button>
        )}

        {onWallet && isTicketIsAddToWallet(data) && (
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Add to wallet'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/wallet.svg'
              onClick={() => onWallet(data)}
            />
          </Button>
        )}

        {onShareByWahtsApp && canShareWhatsapp(data) && (
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by WhatsApp'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <KTSVG
              className={clsx('h-100 w-100 opacity-50')}
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/whatsapp.svg'
              style={{color: isHovered ? '#5f9347' : 'white'}}
              onClick={() => onShareByWahtsApp(data)}
            />
          </Button>
        )}

        {onShareByEmail && isActive(data) && (
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by email'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/mail.svg'
              onClick={() => onShareByEmail(data)}
            />
          </Button>
        )}

        {onShareViaEmail && canShareWhatsapp(data) && (
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by Email'
            style={{
              width: iconWidth ? iconWidth : '30px',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <KTSVG
              style={{color: isHovered ? '#852ece' : 'white'}}
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/mail.svg'
              onClick={() => onShareViaEmail(data)}
            />
          </Button>
        )}
      </div>
    </>
  )
}

export const canShareWhatsapp = (data: TicketPortalModel) => {
  if (data.productIsDownloadable && data.status === 'active') return true
  return false
}
export const isActive = (data: TicketPortalModel) => {
  if (data.status === 'active') return true
  return false
}
